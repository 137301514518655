import type moment from 'moment';

export const MOMENT_ISODATE_FORMAT = `YYYY-MM-DD`;

export function formattedMomentDuration(duration: moment.Duration) {
  const days = Math.floor(duration.asDays());
  const daysPrefix = days === 0 ? '' : `${days}j `;
  const hhmmss = [duration.hours(), duration.minutes(), duration.seconds()]
    .map((value) => String(value).padStart(2, '0'))
    .join(':');
  return `${daysPrefix}${hhmmss}`;
}

export function dateStartLimit(date?: Date): Date {
  const now = new Date();
  const limit = new Date(now.getFullYear() - 2, 0, 1, 0, 0, 0);
  if (!date || date < limit) {
    return limit;
  }
  return date;
}

export function dateEndLimit(date?: Date): Date {
  const limit = new Date();
  if (!date || date > limit) {
    return limit;
  }
  return date;
}
