export interface User {
  id: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  birth_date: string;
  gender: string;
  phone: string;
  nickname: string;
  created_at: string;
  updated_at: string;
  preferred_username: string;
  user_id: string;
  user_roles: string[];
  addresses: Address[];
  enabled: boolean;
}

export interface UserAPI {
  firstname?: string;
  lastname?: string;
  name?: string;
  is_company: boolean;
  emails: Email[];
  phones: string[];
  addresses: AddressAPI[];
}

interface Email {
  value: string;
  isPrincipal: boolean;
}

interface AddressAPI {
  street: string;
  zip: string;
  city: string;
  country: string;
  type: 'principal';
}

export interface Address {
  type: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
}

export interface GetChargeBody {
  dateStart?: string;
  dateEnd?: string;
  badges?: string[];
  drivers?: string[];
  bornes?: string[];
  page?: number;
}

export interface Charge {
  id: string;
  site: {
    borne: {
      id: string;
      name: string;
    };
  };
  start_date_time: string;
  end_date_time: string;
  duration: string;
  total_energy_wh: number;
  total_energy_kwh: number;
  price?: {
    value: number;
    currency: string;
  };
  backend: string;
  tag_id: string;
  token: {
    id: string;
    type: string;
    rfid_uid: string;
    ema_id: string;
  };
  last_updated: string;
}

export type ChargePage = {
  _metadatas: {
    limit: number;
    total: number;
    max_pages: number;
    page: number;
  };
  data: Charge[];
};

export interface Borne {
  id: string;
  backend: string;
  name: string;
  date_releve?: string;
  user_id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
}

export interface Badge {
  id: string;
  rfid_uid: string;
  issuer: string;
  driver: string;
  last_updated: string;
  valid: boolean;
}

export interface Driver {
  id: string;
  ema_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  badges: Badge[];
  last_updated: string;
}

export interface TokenData {
  expires_in: string;
  refresh_token: string;
  access_token: string;
}

export type ISODateTime = string;

export type ExpirableJWTTokens = {
  refresh_token: string;
  access_token: string;
  refresh_token_expired_by: ISODateTime;
  access_token_expired_by: ISODateTime;
};

export interface UserEquipments {
  bornes: Borne[];
  badges: Badge[];
  drivers: Driver[];
}

export interface GestionnaireBorne {
  id: string;
  name: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  custom_name: string;
}

export interface GestionnaireBadge {
  id: string;
  rfid_uid: string;
  ema_id: string;
  issuer: string;
  driver: string;
  last_updated: string;
  valid: boolean;
  user_id: string;
  user_first_name: string;
  user_last_name: string;
  user_email: string;
  custom_name: string;
}

export const EQUIPMENT_PERSONAS = ['user', 'gestionnaire'] as const;
export type EquipmentPersona = (typeof EQUIPMENT_PERSONAS)[number];

export type EquipmentCollectionName = 'badges' | 'bornes';

export interface GestionnaireEquipments {
  bornes: GestionnaireBorne[];
  badges: GestionnaireBadge[];
  drivers: Driver[];
}

export interface Equipments {
  user: UserEquipments;
  gestionnaire: GestionnaireEquipments;
}

export interface Article {
  id: string;
  link: string;
  title: string;
  date: string;
  content: string;
  image: string;
}

export interface PersonalNameEquipment {
  custom_name: string;
}

export interface Context {
  correlationId: string;
}

export interface Alert {
  message: string;
}

export interface StatsCharges {
  total_charges: number;
  total_energy: number;
  average_duration: string;
  average_energy: number;
}

export type InvoicePage = {
  _metadatas: {
    limit: number;
    total: number;
    max_pages: number;
    page: number;
  };
  data: {
    invoices: Invoice[];
    releves: Invoice[];
  };
};

export interface Invoice {
  name: string;
  contract: TypeContract;
  created_at: string;
  due_date: string;
  total_amount: number; //en centimes
  payment_state: PaymentState;
  company: Company;
  lines: InvoiceLine[];
}

export type TypeContract = 'borne' | 'generic' | 'badge';

export type PaymentState = 'paid' | 'not_paid' | 'reversed' | 'in_payment' | 'partial';

export type Company = 'BRS' | 'BRPark';

export interface InvoiceLine {
  name: string;
  amount: number; //en centimes
  odoo_contract_line_ref: number;
}
