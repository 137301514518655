import { defineStore } from 'pinia';
import { type Charge, EQUIPMENT_PERSONAS, type EquipmentPersona, type StatsCharges } from '#shared/types';
import { fetchAllCharges, fetchLastCharges } from '~/services/charges.service';
import moment from 'moment';
import { formattedMomentDuration } from '#shared/dates';

const LAST_CHARGES_COUNT = 5;

export const useCharges = defineStore('charges', () => {
  const _perPersonaCharges: Record<
    EquipmentPersona,
    Ref<{
      charges: Charge[];
      stats: StatsCharges | undefined;
      loadingStatus: 'not-loading' | 'nothing-loaded' | 'last-charges-loaded' | 'all-charges-loaded';
    }>
  > = {
    gestionnaire: ref({ charges: [], stats: undefined, loadingStatus: 'not-loading' }),
    user: ref({ charges: [], stats: undefined, loadingStatus: 'not-loading' }),
  };
  const resetCachedCharges = () => {
    _perPersonaCharges.gestionnaire.value = { charges: [], stats: undefined, loadingStatus: 'not-loading' };
    _perPersonaCharges.user.value = { charges: [], stats: undefined, loadingStatus: 'not-loading' };
  };

  const { perPersonasEquipmentIdsRef, currentEquipmentPersona } = storeToRefs(useEquipments());

  const chargesStats = (charges: Charge[]): StatsCharges => ({
    total_charges: charges.length,
    total_energy: charges.reduce((acc, charge) => acc + charge.total_energy_kwh, 0),
    average_duration: formattedMomentDuration(
      moment.duration(
        charges.length
          ? Math.round(
              charges.reduce((acc, charge) => acc + moment(charge.end_date_time).diff(charge.start_date_time), 0) /
                charges.length,
            )
          : 0,
      ),
    ),
    average_energy: charges.length
      ? charges.reduce((acc, charge) => acc + charge.total_energy_kwh, 0) / charges.length
      : 0,
  });

  watch(
    [perPersonasEquipmentIdsRef],
    ([perPersonasEquipmentIds]) => {
      EQUIPMENT_PERSONAS.forEach(async (persona) => {
        const equipmentIds = perPersonasEquipmentIds[persona];

        if (equipmentIds && equipmentIds.badgeIds.length + equipmentIds.borneIds.length > 0) {
          _perPersonaCharges[persona].value = {
            charges: [],
            stats: undefined,
            loadingStatus: 'nothing-loaded',
          };

          const lastCharges = await fetchLastCharges({
            everyBadgeIds: equipmentIds.badgeIds,
            everyBorneIds: equipmentIds.borneIds,
          });

          _perPersonaCharges[persona].value = {
            charges: lastCharges.data,
            stats: undefined,
            loadingStatus: 'last-charges-loaded',
          };

          const otherCharges = await fetchAllCharges({
            everyBadgeIds: equipmentIds.badgeIds,
            everyBorneIds: equipmentIds.borneIds,
            startingFromPage: 2,
            toPage: lastCharges._metadatas.max_pages,
          });

          const allCharges = lastCharges.data.concat(otherCharges);
          _perPersonaCharges[persona].value = {
            charges: allCharges,
            stats: chargesStats(allCharges),
            loadingStatus: 'all-charges-loaded',
          };
        }
      });
    },
    { immediate: true },
  );

  const currentPersonaCharges = computed(() => {
    const currentPersona = toValue(currentEquipmentPersona),
      gestionnaireCharges = toValue(_perPersonaCharges['gestionnaire']),
      userCharges = toValue(_perPersonaCharges['user']);

    return currentPersona === 'user' ? userCharges : gestionnaireCharges;
  });

  const currentPersonaLastCharges = computed(() => {
    const data = toValue(currentPersonaCharges);

    return {
      ...data,
      charges: data.charges.filter((charge, index) => index < LAST_CHARGES_COUNT),
    };
  });

  return {
    chargesStats,
    currentPersonaCharges,
    currentPersonaLastCharges,
    resetCachedCharges,
  };
});
